import { ContactBanner } from "../components/ContactBanner"


export const Contactos = () => {



     return (
          <>
         <ContactBanner/>
         
          
          </>
     )


}